.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  border-spacing: 0;
  width: 100%;
}

.rc-pagination-total-text {
  margin-right: auto;
}

.rc-pagination-next:focus,
.rc-pagination-jump-next:focus,
.rc-pagination-jump-prev:focus,
.rc-pagination-item:focus,
.rc-pagination-prev:focus {
  outline: none;
}

.gm-style .gm-style-iw-c {
  box-shadow: 0 2px 20px 1px rgba(0, 0, 0, 0.2);
}

.gm-style .gm-style-iw-c button {
  top: 0 !important;
  right: 0 !important;
}

.gm-style .gm-style-iw-c button img {
  width: 20px !important;
  height: 20px !important;
  position: relative;
  right: 7px;
}
.mapOverview{
  overflow-y: visible !important;
  z-index: 1;
}
